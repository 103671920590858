import React from "react"
import { Link } from "gatsby"
import { useLocationData } from "../../hooks/LocationContext"

export default function InnerLink({ pathname, text, onClick }) {
  const locationData = useLocationData()
  if (locationData.pathname === pathname) {
    return (
      <li onClick={onClick}>
        {/* <Link to={pathname} style={{ color: "#e50012" }}> */}
        <Link to={pathname}>{text}</Link>
      </li>
    )
  } else {
    return (
      <li>
        <Link to={pathname}>{text}</Link>
      </li>
    )
  }
}

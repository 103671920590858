import React, { useState } from "react"
import { Link } from "gatsby"
import { Link as ReactScroll } from "react-scroll"

export default function LinkWithScroll({ pathname, hashname, text, onClick }) {
  // const [state, setState] = useState({ hashname, isActive: false })
  // console.log("hashname:", hashname)

  if (pathname === "/") {
    return (
      <li>
        <ReactScroll
          to={hashname.slice(1)}
          offset={-60}
          duration={300}
          smooth
          onClick={onClick}
        >
          {text}
        </ReactScroll>
      </li>
    )
  }
  return (
    <li>
      <Link to={`/${hashname}`}>{text}</Link>
    </li>
  )
}

import React, { useState, useContext } from "react"
import styled, { css } from "styled-components"
import { media } from "../../styles/media"
import MenuList from "./MenuList"
import HeaderOverWrapper from "../HeaderOverWrapper"
import { HeaderOverWrappedContext } from "../../hooks/HeaderOverWrappedContext"

// export const query = graphql`
//   {}
// `

const StyledWrapper = styled.div`
  // height: 100vh;
  // width: 60px;
  background: white;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 10;
  ${() =>
    media.sp(css`
      height: 60px;
      z-index: 1099;
      background: transparent;
      right: 0;
    `)}
`

const StyledNavBtn = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  z-index: 1100;
  cursor: pointer;
  #btnDesign {
    background: white;
    width: 60px;
    height: 18px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-around;
    span {
      background: grey;
      height: 2px;
      border-radius: 3px;
      width: 45%;
    }
  }
  ${() =>
    media.sp(css`
      z-index: 100;
      #btnDesign {
        background: transparent;
        height: 40px;
        justify-content: center;
        z-index: 100;
        span:nth-child(1) {
          margin-bottom: 5px;
        }
      }
    `)}
`

const Header = () => {
  const [isMenuListActive, setIsMenuListActive] = useState(null)
  const [state] = useContext(HeaderOverWrappedContext)
  return (
    <>
      <StyledWrapper>
        <StyledNavBtn onClick={() => setIsMenuListActive(!isMenuListActive)}>
          <span id="btnDesign">
            <span className="btnTop"></span>
            <span className="btnBottom"></span>
          </span>
        </StyledNavBtn>
        {state.isDisplay && <HeaderOverWrapper />}
      </StyledWrapper>
      <MenuList
        isMenuListActive={isMenuListActive}
        setIsMenuListActive={setIsMenuListActive}
      />
    </>
  )
}

export default Header

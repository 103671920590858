import React from "react"
import styled, { css } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import Image from "gatsby-image"
import { BiCopyright } from "react-icons/bi"
import { GrYoutube, GrFacebook } from "react-icons/gr"
import { FaExternalLinkAlt } from "react-icons/fa/"
import { IconContext } from "react-icons"

import FooterLogoImage from "../../images/francoisLogoBorder2.svg"
import { media } from "../../styles/media"

const Outbound = () => (
  <IconContext.Provider value={{ color: "white", size: "1em" }}>
    <FaExternalLinkAlt />
  </IconContext.Provider>
)

const StyledFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  z-index: 0;
  .footBackImg {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    .gatsby-image-wrapper {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    &:after {
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      top: 0;
      right: 0;
      z-index: 0;
      position: absolute;
    }
  }
  #footer {
    background: transparent;
    padding: 100px 0 10px;
    margin-left: 60px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    .footerTopLogo {
      width: 100%;
      margin-bottom: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 80px;
      }
    }
  }
  .snsSection {
    width: 100%;
    padding: 50px 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    svg {
      margin: 0 30px;
      transform: scale(1.6);
      path {
        color: white;
      }
    }
  }
  .footerCopy p {
    color: white;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    svg {
      margin-right: 0.62em;
      path {
        color: white;
      }
    }
  }
  ${() =>
    media.sp(css`
      #footer {
        margin-left: 0;
        padding-top: 30px;
        .footerTopLogo {
          img {
            width: 50px;
          }
        }
      }
    `)}
`

const StyledFooterNav = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: flex-start;
  padding-bottom: 1.62em;
  .footerNav1 {
    color: white;
    width: 30%;
    margin: 0 50px;
    display: flex;
    flex-flow: column nowrap;
    align-items: centner;
    text-shadow: 0px 0px 3px black;
    h4 {
      a {
        margin: 0;
      }
      padding: 10px 0 30px;
      font-weight: bold;
      color: white;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      svg {
        margin-left: 10px;
        path {
          color: white;
        }
      }
    }
    a {
      margin: 16px 0;
      padding-left: 1.62em;
    }
  }
  ${() =>
    media.tablet(css`
      width: 90%;
      .footerNav1 {
        width: 45%;
        margin: 0;
      }
    `)}
  ${() =>
    media.sp(css`
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      .footerNav1 {
        width: 80%;
        align-items: center;
        margin: 20px 0;
        a {
          margin: 8px 0;
        }
      }
    `)}
`

const StyledLang = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  p {
    color: white;
    margin-bottom: 0.32em;
  }
  .langList {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    a {
      padding: 0.62em 1.62em;
      color: white;
    }
    span {
      display: block;
      content: "";
      height: 20px;
      width: 1px;
      background: #dadada;
    }
  }
`

const Footer = () => {
  const data = useStaticQuery(query)
  return (
    <StyledFooterWrapper>
      <div className="footBackImg">
        <Image
          fluid={data.zoo.footerMedia.childImageSharp.fluid}
          alt="footer background image"
        />
      </div>
      <footer id="footer">
        <div className="footerTopLogo">
          <img src={FooterLogoImage} alt="logo" />
        </div>
        {/* Navigation section */}
        <StyledFooterNav>
          <div className="footerNav1">
            <h4>フランソアグループ・企業情報</h4>
            <Link to="/company-profiles/">グループ構成</Link>
            <Link to="/company-locations/">事業拠点一覧</Link>
            <Link to="/company-vision/">企業理念・沿革</Link>
            <Link to="/company-activities/">活動・取り組み</Link>
            <Link to="/recruitment/">採用情報</Link>
            <Link to="/inquiry/">企業に関するお問合せ</Link>
          </div>
          <div className="footerNav1">
            <h4>
              <a
                href="https://www.francois.co.jp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                株式会社フランソア・商品情報
                <Outbound />
              </a>
            </h4>
            <a
              href="https://www.francois.co.jp/thismonth-products/"
              target="_blank"
              rel="noopener noreferrer"
            >
              新商品情報
            </a>
            {/* <a
              href="https://www.francois.co.jp/allergen-search/"
              target="_blank"
              rel="noopener noreferrer"
            >
              アレルゲン検索
            </a> */}
            <a
              href="https://www.francois.co.jp/approach/"
              target="_blank"
              rel="noopener noreferrer"
            >
              おいしいパンを求めて
            </a>
            <a
              href="https://www.francois.co.jp/recipe-book/"
              target="_blank"
              rel="noopener noreferrer"
            >
              パンレシピ
            </a>
            <a
              href="https://www.francois.co.jp/cm-youtube/"
              target="_blank"
              rel="noopener noreferrer"
            >
              CM
            </a>
            <a
              href="https://www.francois.co.jp/inquiry/"
              target="_blank"
              rel="noopener noreferrer"
            >
              商品に関するお問合せ
            </a>
          </div>
        </StyledFooterNav>

        <StyledLang>
          <p>Language</p>
          <div className="langList">
            <Link to="/en/toppage/">EN</Link>
            <span></span>
            <Link to="/cn/toppage/">CN</Link>
          </div>
        </StyledLang>

        {/* SNS section */}
        <div className="snsSection">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/user/francoisjp"
          >
            <GrYoutube />
          </a>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.facebook.com/francois.co.jp"
          >
            <GrFacebook />
          </a>
        </div>

        {/* Copy right section */}
        <div className="footerCopy">
          <p>
            <BiCopyright />
            2021 Francois Co., Ltd.
          </p>
        </div>
      </footer>
    </StyledFooterWrapper>
  )
}

export const query = graphql`
  {
    zoo: strapiIndexParts {
      footerMedia {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

export default Footer

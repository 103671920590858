import React from "react"
import styled, { css } from "styled-components"

const StyledOuterLink = styled.li(() =>
  css(`
    
`)
)

export default function OuterLink({ href, text, onClick }) {
  return (
    <StyledOuterLink onClick={onClick}>
      <a href={href} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    </StyledOuterLink>
  )
}

import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import LogoWhite from "../../images/francoisLogoENwhite2.svg"
import { media } from "../../styles/media"
import { X } from "react-feather"
import LinkWithScroll from "./LinkWithScroll"
import { useLocationData } from "../../hooks/LocationContext"
import InnerLink from "./InnerLink"
import OuterLink from "./OuterLink"

const StyledMenuList = styled.div`
  background: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -100%;
  opacity: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  padding-top: 15%;
  justify-content: center;
  .backImage {
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 0.1;
    width: 80%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
  }
  .closeTo {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 44px;
    height: 44px;
    top: 10%;
    left: 10%;
    &:hover {
      cursor: pointer;
    }
  }
  .menuLists {
    width: 30%;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    color: white;
    z-index: 100;
    span {
      font-size: 1.32em;
      font-weight: bold;
      width: 100%;
      color: #dadada;
      display: flex;
      flex-flow: column nowrap;
      &:after {
        display: block;
        width: 15%;
        background: ${({ theme }) => theme.colors.primary.red};
        height: 2px;
        content: "";
        margin: 10px 0 15px;
      }
    }
    a {
      padding: 15px 0;
      margin-left: 1.62em;
      transition: 0.3s;
      &:hover {
        color: ${({ theme }) => theme.colors.primary.green};
        text-shadow: 0px 0px 2px green;
      }
    }
  }
  .menuLists2 span:after {
    background: ${({ theme }) => theme.colors.primary.green};
  }

  animation: ${props => {
    if (props.isMenuListActive !== null) {
      if (props.isMenuListActive) {
        return `menu-slide-in 0.4s linear 0s forwards`
      }
      return `menu-slide-out 0.2s linear 0s forwards`
    }
  }};
  z-index: 1000;
  @keyframes menu-slide-in {
    from {
      transform: translateX(0);
      opacity: 0;
    }
    to {
      transform: translateX(100%);
      opacity: 1;
    }
  }
  @keyframes menu-slide-out {
    from {
      transform: translateX(100%);
      opacity: 0.8;
    }
    to {
      transform: translateX(0);
      opacity: 0;
    }
  }
  ${() =>
    media.tablet(css`
      padding-top: 30%;
    `)}
  ${() =>
    media.sp(css`
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      padding: 0;
      .backImage {
        img {
          width: 80%;
        }
      }
      .closeTo {
        display: none;
      }
      .menuLists {
        width: 90%;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        span {
          font-size: 1em;
          width: 100%;
        }
        a {
          padding: 0.62em 0;
          width: 40%;
        }
      }
      .menuLists2 {
        margin-top: 50px;
      }
    `)}
`

const StyledLinkList = styled.ul(css`
  width: 100%;
  color: #fff;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 2rem;
`)

const MenuList = ({ isMenuListActive, setIsMenuListActive }) => {
  const locationData = useLocationData()
  function toInactiveMenuList() {
    setIsMenuListActive(false)
  }
  return (
    <StyledMenuList isMenuListActive={isMenuListActive}>
      <div className="backImage">
        <img src={LogoWhite} alt="francoisLogo" />
      </div>

      <div
        className="closeTo"
        onClick={toInactiveMenuList}
        onKeyDown={toInactiveMenuList}
        role="button"
        tabIndex={0}
      >
        <X color="white" size={33} />
      </div>

      <StyledLinkList>
        <OuterLink
          href="https://www.francois.co.jp"
          text="商品情報"
          onClick={toInactiveMenuList}
        />
        <LinkWithScroll
          onClick={toInactiveMenuList}
          pathname={locationData.pathname}
          hashname="#corpProfile"
          text="会社情報"
        />
        <LinkWithScroll
          onClick={toInactiveMenuList}
          pathname={locationData.pathname}
          hashname="#bizProfile"
          text="事業紹介"
        />
        <InnerLink
          pathname="/company-activities/"
          text="活動・取り組み"
          onClick={toInactiveMenuList}
        />
        <OuterLink
          href="https://sdgs.francois-group.jp"
          text="SDGs"
          onClick={toInactiveMenuList}
        />
        <InnerLink
          pathname="/recruitment"
          text="採用情報"
          onClick={toInactiveMenuList}
        />
        <InnerLink
          pathname="/inquiry"
          text="お問合せ"
          onClick={toInactiveMenuList}
        />
      </StyledLinkList>

      {/* <div className="menuLists">
        <span>事業紹介</span>
        <Link to="/ourbusiness/francois/">製パン事業</Link>
        <Link to="/ourbusiness/nbk/">ベンダー事業</Link>
        <Link to="/ourbusiness/bakery-shop/">直営店事業</Link>
        <Link to="/ourbusiness/menshoku/">麺弁当製造事業</Link>
        <Link to="/ourbusiness/sweets/">菓子製造事業</Link>
        <Link to="/ourbusiness/flower-dep/">花事業</Link>
        <Link to="/ourbusiness/overseas-venture/">海外事業</Link>
      </div>
      <div className="menuLists menuLists2">
        <span>グループ企業情報</span>
        <Link to="/company-profiles/">グループ構成</Link>
        <Link to="/company-locations/">拠点一覧</Link>
        <Link to="/company-vision/">企業理念・沿革</Link>
        <Link to="/company-activities/">活動・取り組み</Link>
        <Link to="/recruitment/">採用情報</Link>
        <Link to="/inquiry/">企業に関するお問合せ</Link>
      </div> */}
    </StyledMenuList>
  )
}

export default MenuList

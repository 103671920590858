import React, { useContext } from "react"

import styled, { css } from "styled-components"
import { Link } from "gatsby"
import InnerLink from "./InnerLink"
import CorpLogo from "../../images/francoisLogoNonBorder.svg"
// import UnderlineLinks from "./UnderlineLinks"
import LinkWithScroll from "./LinkWithScroll"
import { media } from "../../styles/media"
import OuterLink from "./OuterLink"
import { useActiveState, useLocationData } from "../../hooks/LocationContext"

const StyledDiv = styled.div`
  width: 100%;
  height: 60px;
  position: fixed;
  z-index: 999;
  background: white;
  box-shadow: 0px 1px 0px #dadada;
  header {
    width: 100%;
    height: 100%;
    padding-right: 5%;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    .headRight {
      width: 60px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      ${() =>
        media.sp(css`
          margin-left: 3%;
        `)}
      a img {
        width: 50px;
      }
    }
    .headerLink {
      ${() =>
        media.sp(css`
          display: none;
        `)}
      margin-right:0px;
      // width: 300px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      font-size: 1rem;
      font-weight: 500;
      li {
        color: #333333;
        padding
        cursor: pointer;
        border-inline-start: 2px solid #e6e6e6;
        padding-block: 1px;
        padding-inline: 12px;
        &:last-child {
          border-inline-end: 2px solid #e6e6e6;
        }
        &:hover {
          color: #e50012;
          cursor: pointer;
        }
        // &:active {
        //   color: #e50012;
        // }
      }
      @media only screen and (max-width: 760px) {
        font-size: 14px;
      }
      @media only screen and (max-width: 690px) {
        font-size: 13px;
      }

    }
  }
`

const HeaderTop = () => {
  const locationData = useLocationData()
  return (
    <StyledDiv>
      <header>
        <div className="headRight">
          <Link to="/">
            <img src={CorpLogo} alt="フランソア会社ロゴ" />
          </Link>
        </div>
        <ul className="headerLink">
          <OuterLink href="https://www.francois.co.jp" text="商品情報" />
          <LinkWithScroll
            pathname={locationData.pathname}
            hashname="#corpProfile"
            text="会社情報"
          />
          <LinkWithScroll
            pathname={locationData.pathname}
            hashname="#bizProfile"
            text="事業紹介"
          />
          <InnerLink pathname="/company-activities" text="活動・取り組み" />
          <OuterLink href="https://sdgs.francois-group.jp" text="SDGs" />
          <InnerLink pathname="/recruitment" text="採用情報" />
          <InnerLink pathname="/inquiry" text="お問合せ" />

          {/* <UnderlineLinks color="#E50012" link2="/inquiry/" text="お問合せ" />
        <UnderlineLinks color="#008742" link2="/recruitment/" text="採用情報" /> */}
        </ul>
      </header>
    </StyledDiv>
  )
}

export default HeaderTop
